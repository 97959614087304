import React from 'react';
import { Link } from "gatsby";
import { navigate } from "gatsby";

import "./style.scss"
import Stars from "../components/Rating/"
import SEO from "../components/seo"

const Casino = ({ pageContext }) => (
    <div className="casino-page">
        <SEO 
            title={pageContext.name + " har över " + pageContext.gameQuantity + " spel och " + pageContext.titlePitch + " | Casinolabbet.se"} 
            description={"Hos " + pageContext.name + " hittar du " + pageContext.sellingPointOne + ", " + pageContext.sellingPointTwo + " och " + pageContext.sellingPointThree + "."} />

        <div className="info-canvas">

        <div className="info-content">
            <div className="info-content-header">
                <div className="info-content-close" style={{color : pageContext.colorTwo}} onClick={() => navigate(-1)}>
                    Stäng <span className="material-icons">close</span>
                </div>
                <div className="info-content-logo">
                    <img src={pageContext.logo} title={pageContext.name} alt={pageContext.name} />
                </div>
                <div className="info-content-rating" style={{backgroundColor : pageContext.colorOne}}>
                    {Stars(pageContext.rating, pageContext.colorTwo)}
                    <a href={pageContext.url} className="goto" target="_blank" style={{color : pageContext.colorOne, backgroundColor : pageContext.colorTwo}}>
                        <span className="material-icons">play_arrow</span> Besök {pageContext.name}
                    </a>
                </div>
                <ul className="info-content-selling-points">
                    <li className="info-content-selling-point">
                        {pageContext.sellingPointOne}
                    </li>
                    <li className="info-content-selling-point">
                        {pageContext.sellingPointTwo}
                    </li>
                    <li className="info-content-selling-point">
                        {pageContext.sellingPointThree}
                    </li>
                </ul>
            </div>

            <div className="info-content-content">
                <h1>{pageContext.name}</h1>
                <p dangerouslySetInnerHTML={{ __html: pageContext.about }} />
                <div className="info-content-info">
                    <div className="info-content-fast-info">
                    <h3>Snabbfakta om {pageContext.name}</h3>
                    <ul>
                        <li>Svensk licens: {pageContext.swedishLicense}</li>
                        <li>Lanserades: {pageContext.launch}</li>
                        <li>BankID: {pageContext.bankId}</li>
                        <li>Speltyp: 
                            <ul className="game-types">
                                {pageContext.gameTypes.casino && <li>Casino</li>}
                                {pageContext.gameTypes.livecasino && <li>Live Casino</li>}
                                {pageContext.gameTypes.betting && <li>Betting</li>}
                                {pageContext.gameTypes.bingo && <li>Bingo</li>}
                                {pageContext.gameTypes.poker && <li>Poker</li>}
                            </ul>
                        </li>
                        <li>Betalningar: 
                        <ul className="payments">
                            {pageContext.payments.swish && <li>Swish</li>}
                            {pageContext.payments.trustly && <li>Trustly</li>}
                            {pageContext.payments.visa && <li>Visa</li>}
                            {pageContext.payments.mastercard && <li>Mastercard</li>}
                            {pageContext.payments.paysafecard && <li>Paysafecard</li>}
                            {pageContext.payments.skrill && <li>Skrill</li>}
                            {pageContext.payments.neteller && <li>Neteller</li>}
                            {pageContext.payments.paypal && <li>PayPal</li>}
                            {pageContext.payments.zimpler && <li>Zimpler</li>}
                            {pageContext.payments.brite && <li>Brite</li>}
                            {pageContext.payments.klarna && <li>klarna</li>}
                            {pageContext.payments.siru && <li>siru</li>}
                            {pageContext.payments.banktransfer && <li>Banköverföring</li>}
                        </ul>
                        </li>
                        <li>Bolag: {pageContext.company}</li>
                        <li>Casinospel: {pageContext.gameQuantity}</li>
                        <li>Spelleverantörer:
                            <ul className="providers">
                                {pageContext.providers.pragmaticplay && <li>Pragmatic Play</li>}
                                {pageContext.providers.netent && <li>NetEnt</li>}
                                {pageContext.providers.redtiger && <li>Red Tiger</li>}
                                {pageContext.providers.microgaming && <li>Microgaming</li>}
                                {pageContext.providers.yggdrasil && <li>Yggdrasil</li>}
                                {pageContext.providers.quickspin && <li>Quickspin</li>}
                                {pageContext.providers.elkstudios && <li>ELK Studios</li>}
                                {pageContext.providers.relaxgaming && <li>Relax Gaming</li>}
                                {pageContext.providers.thunderkick && <li>Thunderkick</li>}
                                {pageContext.providers.nolimitcity && <li>Nolimit City</li>}
                                {pageContext.providers.nextgen && <li>NextGen</li>}
                                {pageContext.providers.bigtimegaming && <li>Big Time Gaming</li>}
                                {pageContext.providers.playngo && <li>Play'n GO</li>}
                                {pageContext.providers.pushgaming && <li>Push Gaming</li>}
                                {pageContext.providers.hacksaw && <li>Hacksaw</li>}
                                {pageContext.providers.evolution && <li>Evolution</li>}
                                {pageContext.providers.justforthewin && <li>Just For The Win</li>}
                                {pageContext.providers.gamomat && <li>Gamomat</li>}
                                {pageContext.providers.isoftbet && <li>iSoftBet</li>}
                                {pageContext.providers.blueprint && <li>Blueprint</li>}
                                {pageContext.providers.nyx && <li>Nyx</li>}
                            </ul>
                        </li>
                        <li>Webbsida: <a href={pageContext.url} target="_blank">{pageContext.website}</a></li>
                    </ul>
                    </div>
                    <div className="info-content-offer"> 
                    <h3>{pageContext.name} erbjudande</h3>
                    {pageContext.bonus ? (
                        <div>
                        <h4 style={{color : pageContext.colorTwo, backgroundColor : pageContext.colorOne, borderColor : pageContext.colorTwo}}>{pageContext.bonusPitch}</h4>
                            <ul className="bonus-terms" dangerouslySetInnerHTML={{ __html: pageContext.bonusTerms }} />
                        </div>
                    ) : (
                        <div>
                        <h4 style={{color : pageContext.colorTwo, backgroundColor : pageContext.colorOne, borderColor : pageContext.colorTwo}}>{pageContext.name} har inga erbjudande för tillfället.</h4>
                        Ta en titt på andra <Link to="/erbjudande/">casino med bonusar</Link>.
                        </div>
                    )}
                    </div>
                    <div className="info-content-outbound">
                        <a href={pageContext.url} target="_blank" className="info-content-outbound-goto" style={{color : pageContext.colorTwo, backgroundColor : pageContext.colorOne}}>
                            Till {pageContext.name}
                        </a>
                        <p className="info-content-goto-tac" dangerouslySetInnerHTML={{ __html: pageContext.tac }} />
                    </div>
                </div>
            </div>
        </div>
        <div className="off-canvas" style={{backgroundColor : pageContext.colorOne}} onClick={() => navigate(-1)}></div>
    </div>
    </div>
)

export default Casino