import React from 'react';
import './style.scss';

const stars = (rating, colorTwo) => {
    const number = parseInt(rating);

    if(number === 5) {
        return (
            <div className='stars' style={{color : colorTwo}}>
                <i className="material-icons">star</i>
                <i className="material-icons">star</i>
                <i className="material-icons">star</i>
                <i className="material-icons">star</i>
                <i className="material-icons">star</i>
            </div>
        )
    }
    if(number === 4) {
        return (
            <div className='stars' style={{color : colorTwo}}>
                <i className="material-icons">star</i>
                <i className="material-icons">star</i>
                <i className="material-icons">star</i>
                <i className="material-icons">star</i>
                <i className="material-icons empty">star_border</i>
            </div>
        )
    }
    if(number === 3) {
        return (
            <div className='stars' style={{color : colorTwo}}>
                <i className="material-icons">star</i>
                <i className="material-icons">star</i>
                <i className="material-icons">star</i>
                <i className="material-icons empty">star_border</i>
                <i className="material-icons empty">star_border</i>
            </div>
        )
    }
    if(number === 2) {
        return (
            <div className='stars' style={{color : colorTwo}}>
                <i className="material-icons">star</i>
                <i className="material-icons">star</i>
                <i className="material-icons empty">star_border</i>
                <i className="material-icons empty">star_border</i>
                <i className="material-icons empty">star_border</i>
            </div>
        )
    }
    if(number === 1) {
        return (
            <div className='stars' style={{color : colorTwo}}>
                <i className="material-icons">star</i>
                <i className="material-icons empty">star_border</i>
                <i className="material-icons empty">star_border</i>
                <i className="material-icons empty">star_border</i>
                <i className="material-icons empty">star_border</i>
            </div>
        )
    }
    if(number === 0) {
        return (
            <div className='stars' style={{color : colorTwo}}>
                <i className="material-icons empty">star</i>
                <i className="material-icons empty">star</i>
                <i className="material-icons empty">star</i>
                <i className="material-icons empty">star</i>
                <i className="material-icons empty">star</i>
            </div>
        )
    }
 };

 export default stars;